export const teamMembers = [
    { name: 'Kyle Rolfing', image: '/headshots/Kyle-BW-450.jpg', title: 'Chief Executive Officer', bio: 'Kyle is a serial healthcare entrepreneur with particular experience in next-generation tools for employers to manage health benefits. Kyle was a founder at Definity Health (acquired by United Health for $300m in 2004), Redbrick Health (acquired by Marlin Equity Partners in 2018), and Bright Health (IPO 2021, NYSE:BHG).', linkedin: 'https://www.linkedin.com/in/kyle-rolfing-56b45227/'},
    { name: 'Gerardo Zampaglione', image: '/headshots/Gerardo-BW-450.jpg', title: 'Founder & Chief Product Officer', bio: 'Gerardo Zampaglione is the Founder of Aegle Capital, the first company that provides adaptive capital solutions to mitigate the volatility associated with high-cost healthcare claims. He brings a wealth of experience spanning population health, value-based care, re/insurance program design, and predictive analytics to the self-funded benefits ecosystem. He is deeply committed to transforming the healthcare space by driving innovation, improving access, and delivering better outcomes for stakeholders across the ecosystem. Prior to founding Aegle, he led billing and population health initiatives at Epic Systems, the leading EMR vendor, and advised Fortune 500 biopharma, medical device, venture capital, and private equity firms on commercial growth strategy, due diligence, and product design through roles at boutique healthcare strategy consultancies. He is a graduate of the Wharton School and Tufts University, and resides in Philadelphia, PA, with his wife, Sofia, and his two dogs.', linkedin: 'https://www.linkedin.com/in/zampaglione/' },
    { name: 'Drew Holm', image: '/headshots/Drew-BW-450.jpg', title: 'Chief Operating Officer', bio: 'Drew brings almost 20 years of experience in healthcare where he has led finance, mergers and acquisitions, strategic partnerships, strategy, HR, and corporate development. Drew was most recently the CFO of Recuro Health and additionally held leadership roles at Constellation, Inc., Stella, the parent company of Blue Cross Blue Shield of Minnesota, and United Health Group.', linkedin: 'https://www.linkedin.com/in/andrew-holm-205b0318/'},
    { name: 'Barry Carlson', image: '/headshots/Barry-BW-450.jpg', title: 'Chief Growth Officer', bio: 'Barry brings a wealth of experience in the insurance, healthcare, and employee benefits domains, with a career spanning more than 30 years. His background is rooted in sales leadership and business development. Barry has had the privilege of working with respected companies including Aon, Nexben, Allina Health/Aetna, Medica and ING.', linkedin: 'https://www.linkedin.com/in/barrycarlson/' },
    { name: 'Jenna Christensen', image: '/headshots/Jenna-BW-450.jpg', title: 'VP, Strategic Operations', bio: 'Jenna brings over 14 years of experience in healthcare where she has led finance, operations and business integration efforts. Prior to joining Aegle, Jenna was Vice President of Operations Enablement at Optum Financial.', linkedin: 'https://www.linkedin.com/in/jenna-christensen-8642ab64/' },
    { name: 'Jarrod Stenberg', image: '/headshots/Jarrod-BW-450.jpg', title: 'Principal Architect, Technology', bio: 'Jarrod brings over 20 years of technology and security experience to his role. He has a proven track record in enhancing organizational scalability and security, with previous positions at Entrust Datacard, UnitedHealth Group, and Wolters Kluwer. At Aegle, Jarrod’s expertise is instrumental in developing robust technology architectures that support and drive the company’s growth.', linkedin: 'https://www.linkedin.com/in/jstenberg/' },
    { name: 'Charlie Trepanier', image: '/headshots/Charlie-BW-450.jpg', title: 'VP, Finance and Controller', bio: 'Charlie has over 17 years of accounting and finance experience, primarily in the insurance industry, spending the first 6 years of his career at Ernst & Young auditing insurance companies, including health insurers. He then spent nearly 11 years in accounting and finance roles at medical malpractice insurers Constellation and Curi. Prior to Constellation’s merger with Curi he oversaw all aspects of Constellation’s finance and accounting operations.', linkedin: 'https://www.linkedin.com/in/charlie-trepanier-29451a4/' },
    { name: 'Tye Rambo', image: '/headshots/Tye-BW-450.jpg', title: 'Director of Partnerships', bio: 'As the Director of Partnerships, Tye leads our overall strategic initiatives with our channel partners. Tye brings 15 years of sales/account management experience in the healthcare industry including tenures at Humana, Anthem, and Lockton.', linkedin: 'https://www.linkedin.com/in/tye-rambo-60386a94/' },
    { name: 'Carl Schwappach', image: '/headshots/Carl-BW-450.jpg', title: 'Sales Enablement Manager', bio: 'Carl has extensive experience in the health insurance industry specializing in sales management, operations, customer service and market expansion. Carl has also led training programs, supported regulatory compliance, and collaborated with internal teams to drive sales and market growth.', linkedin: 'https://www.linkedin.com/in/carlschwappach/' },
    { name: 'Erika Townley', image: '/headshots/Erika-BW-450.jpg', title: 'Executive Assistant & Operations Manager', bio: 'Erika brings 10 years worth of experience in healthcare startups where she built out processes and offices from the ground up. Prior to joining Aegle, Erika worked at Bright Health and Learn to Live.', linkedin: 'https://www.linkedin.com/in/erikatownley/' },
    { name: 'Blake Thompson', image: '/headshots/Blake-BW-450.jpg', title: 'Operations Manager', bio: 'Blake is an accomplished Operations Manager who operates at the intersection of technology, data, and processes to drive efficiency and innovation. With a proven background in healthcare startups, he excels in fast-paced environments, leveraging his expertise to deliver impactful solutions and spearhead industry transformation.', linkedin: 'https://www.linkedin.com/in/blakethompson10/' },
    { name: 'Dillon Baxendell', image: '/headshots/Dillon-BW-450.jpg', title: 'UX Engineer', bio: 'Dillon is a UX Engineer with expertise in React, Material UI, and TypeScript, dedicated to designing seamless and user-focused digital experiences. His background in theatre and software development allows him to combine storytelling with technical precision, delivering impactful solutions across healthcare dashboards, funding applications, and complex UI components.', linkedin: 'https://www.linkedin.com/in/dillonbaxendell/' },
]