import React, { useState } from 'react';
import './AboutPage.css';
import AegleDivider from '../components/AegleDivider';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider, IconButton } from '@mui/material';
import { teamMembers } from './teamMembers';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const AboutPage: React.FC = () => {

    const [open, setOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState<null | typeof teamMembers[0]>(null);

    const handleClickOpen = (member: typeof teamMembers[0]) => {
        setSelectedMember(member);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMember(null);
    };

    return (
        <div className="AboutPage">
            {/* About Us Section */}
            <section className="about-us-section">
                <h1>About Us</h1>
                <AegleDivider color="#368caa" />
                <p>
                    At Aegle, we develop cutting-edge solutions that fundamentally transform the way self-insured and risk-bearing entities manage their healthcare funding risks. Our risk financing solutions are crafted to enhance traditional stop-loss and reinsurance methods, providing a cost-effective and capital-efficient alternative.
                </p>
            </section>

            {/* Our Team Section */}
            <section className="our-team-section">
                <h1>Our Team</h1>
                <AegleDivider color="#368caa" />
                <p>
                    Our team at Aegle Capital is composed of experts from the healthcare, structured finance, reinsurance, alternative risk, and technology sectors who are committed to developing innovative financial solutions in healthcare funding. With a forward-thinking approach to strategy and innovation, we are actively shaping the future of healthcare finance.
                </p>
                <div className="team-grid">
                    {teamMembers.map((member, index) => (
                        <div
                            className="team-member"
                            key={index}
                            onClick={() => handleClickOpen(member)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className="image-wrapper"
                                style={{
                                    backgroundColor: '#192e58',
                                    transition: '0.3s',
                                    position: 'relative',
                                }}
                            >
                                <img src={member.image} alt={member.name} />
                            </div>
                            <h2 className="team-name">{member.name}</h2>
                            <p className="team-title">{member.title}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Dialog for Member Information */}
            {selectedMember && (
    <Dialog open={open} onClose={handleClose} aria-labelledby="team-member-dialog" maxWidth="md" fullWidth>
        <DialogContent>
            <div 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    gap: '1.5rem', 
                    marginTop: '2rem', 
                    flexWrap: 'wrap' // Ensures responsiveness
                }}
            >
                {/* Profile Image */}
                <img
                    src={selectedMember.image}
                    alt={selectedMember.name}
                    style={{
                        width: '200px',
                        height: '200px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        border: '1px solid #E0E0E0',
                        flexShrink: 0 // Prevents shrinking on smaller screens
                    }}
                />
                
                {/* Name, Title & LinkedIn Button */}
                <div style={{ flex: 1, minWidth: '250px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexWrap: 'wrap' }}>
                        {/* Name & Title */}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <h2 style={{ margin: 0, fontWeight: 'bold', color: '#192e58', fontSize: '1.25rem' }}>
                                {selectedMember.name}
                            </h2>
                            <p style={{ margin: 0, fontSize: '1.1rem', fontStyle: 'italic', color: '#368CAA' }}>
                                {selectedMember.title}
                            </p>
                        </div>

                        {/* LinkedIn Button */}
                        {selectedMember.linkedin && (
                            <a 
                                href={selectedMember.linkedin} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <IconButton 
                                    size="large" 
                                    sx={{
                                        color: '#192e58', 
                                        fontSize: '3rem', 
                                        width: '50px', 
                                        height: '50px'
                                    }}
                                >
                                    <LinkedInIcon fontSize="inherit" />
                                </IconButton>
                            </a>
                        )}
                    </div>

                    {/* Bio (always below) */}
                    <p style={{ marginTop: '1rem', lineHeight: '1.75', fontSize: '1rem' }}>
                        {selectedMember.bio}
                    </p>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>
)}
        </div>
    );
};

export default AboutPage;
