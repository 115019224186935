import { useEffect } from "react";
import './ContactPage.css';
import AegleDivider from "../components/AegleDivider";

const ContactPage: React.FC = () => {
    useEffect(() => {
        // Create script element
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
        script.async = true;

        // Append script to body
        document.body.appendChild(script);

        // Cleanup function to remove script when component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
        <section className="contact-us-section">
        <h1>Contact Us</h1>
        <AegleDivider color="#368caa" />
        <p>Schedule a meeting with our team to discuss how we can support your needs and explore potential opportunities</p>
        </section>
        <section className="meeting-section">
        <div className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/carl-schwappach/aegle-website-connect-page?embed=true"
        ></div>
        <p>
            Aegle Capital
        </p>
        <p>
        4999 France Ave. S., Suite 255
        </p>
        <p>
        Minneapolis, MN 55409
        </p>
        <p>
        info@aeglecap.com
        </p>
        </section>
        
        </>
       
    );
};

export default ContactPage;
