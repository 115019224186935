import React from 'react';
import './HomePage.css';
import Button from '@mui/material/Button';
import { Divider, IconButton, useMediaQuery } from '@mui/material';
import AegleDivider from '../components/AegleDivider';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useNavigate } from 'react-router-dom';

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:1173px)');
    return (
        <div className="HomePage">
            {/* Title Section */}
            <section className="HomePage-section title-section">
                <img src="/LogoTeal.png" alt="Logo" className="title-logo" />
                <h1>ADAPTIVE CAPITAL <i>for</i> HEALTHCARE</h1>
                <AegleDivider color="#368CAA" />
                <p>
                Aegle is the first company that provides adaptive capital solutions to mitigate the volatility associated with high-cost healthcare claims.
                </p>
            </section>

            {/* What We Do Section */}
            <section className="HomePage-section what-we-do-section">
            <h2>What We Do</h2>
                <AegleDivider color="#368caa" />
                <p>
                    We provide capital-efficient solutions that address the key challenges self-insured employers face including - the timing of reimbursement, coverage gaps, and high-cost health insurance claims. Aegle's innovative approach redefines how healthcare risk is financed, empowering employers to navigate the complexities of self-insurance with greater confidence.
                </p>
                <div className="three-distinct">
                    <p>
                        We solve three distinct problems:
                    </p>
                </div>

            <div className="what-we-do-content">
                {/* Section 1 */}
                <div className="what-we-do-item">
                    <div className="circle-title">
                        <div className="circle">1</div>
                        <h3>THE TIMING <i>of</i> REIMBURSEMENT</h3>
                    </div>
                    <p>
                        A innovative approach to speeding up the stop loss reimbursement process,
                        easing the financial pressures of delayed reimbursement payments.
                    </p>
                    <Button variant="contained" 
                            color="primary" 
                            sx={{ boxShadow: 'none', '&:hover': { boxShadow: 'none' } }}
                            onClick={() => {
                                window.scrollTo(0, 0);
                                navigate('/accel')
                            }}>
                        Learn More
                    </Button>
                </div>
                <Divider orientation={isMobile ? "horizontal" : "vertical"} flexItem />

                {/* Section 2 */}
                <div className="what-we-do-item">
                    <div className="circle-title">
                        <div className="circle">2</div>
                        <h3>GAPS <i>in</i> COVERAGE</h3>
                    </div>
                    <p>
                        Offers organizations flexible financial solutions to effectively manage claim volatility, converting unpredictable healthcare costs into manageable,
                        scheduled payments.
                    </p>
                    <Button 
                        variant="contained" 
                        color="primary"
                        sx={{ boxShadow: 'none', '&:hover': { boxShadow: 'none' } }}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/riskfinancing')
                        }}
                        disabled
                        >
                        Coming Soon
                    </Button>
                </div>
                <Divider orientation={isMobile ? "horizontal" : "vertical"} flexItem />

                {/* Section 3 */}
                <div className="what-we-do-item">
                    <div className="circle-title">
                        <div className="circle">3</div>
                        <h3>HIGH-COST HEALTH INSURANCE CLAIMS</h3>
                    </div>
                    <p>
                        Empowers organizations to initiate a captive insurance strategy without the necessity for upfront capital.
                    </p>
                    <Button 
                        variant="contained" 
                        color="primary"
                        sx={{ boxShadow: 'none', '&:hover': { boxShadow: 'none' } }}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/rbc')
                        }}
                        disabled
                        >
                        Coming Soon
                    </Button>
                </div>
            </div>
            </section>

            {/* Our Mission Section */}
            <section className="HomePage-section mission-section">
                <h2>Our Mission</h2>
                <AegleDivider color="white" />
                <p>
                To help self-insured employers to better manage their exposure to health expenditures, enabling companies to better manage risk, reserve capital, and operate more efficiently.
                </p>
            </section>
        </div>
    );
};

export default HomePage;
