import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { spokeData } from "./AccelGraphic";

const AccelGraphicMobile: React.FC = () => {
  return (
    <div style={{ width: "100%", padding: "1rem" }}>
      {spokeData.map((spoke) => (
        <Accordion 
          key={spoke.key}
          sx={{ 
            boxShadow: "none", // Removes shadow
            "&:before": { display: "none" }, // Removes default MUI divider line above Accordion
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" sx={{ textAlign: "left" }}>{spoke.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {spoke.details.map((detail, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                {/* Icon */}
                <Box
                  component="img"
                  src={detail.icon}
                  alt="icon"
                  sx={{
                    width: 75,
                    height: "auto",
                    marginRight: 2, // Adjust spacing
                    flexShrink: 0, // Prevents icon from shrinking
                  }}
                />
                {/* Text List */}
                <Box>
                  <ul style={{ margin: 0, paddingLeft: "20px", listStylePosition: "outside" }}>
                    {detail.text.map((t, j) => (
                      <li
                        key={j}
                        style={{
                          fontSize: "1rem",
                          textAlign: "left",
                          lineHeight: "1.75",
                        }}
                      >
                        {t}
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AccelGraphicMobile;