import React from 'react';
import './AccelPage.css';
import { Box, Button, Divider, useMediaQuery } from '@mui/material';
import AegleDivider from '../components/AegleDivider';
import AccelGraphic from '../components/AccelGraphic';
import { useNavigate } from 'react-router-dom';
import AccelGraphicMobile from '../components/AccelGraphicMobile';

const AccelPage: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  return (
    <div>
      {/* Header Section */}
      <section className="header-section">
        <h1>Aegle Accel</h1>
        <AegleDivider color="#368caa" />
        <p className="tagline">Accel is a financial solution that offers immediate reimbursement and helps companies manage the volatility of high-cost claims.</p>
        <p className="benefit-description">
          The independent self-funded market faces critical hurdles like funding delays, cash flow disruption, and complex administrative burdens. Aegle Accel addresses these by:
        </p>
        {/* <Link className="case-study-button" underline='always' sx={{color: 'white'}}>
                    See How One 860-Employee Company Saved $150,000 Annually
                </Link> */}
        <div className="what-we-do-content">
          {/* Section 1 */}
          <div className="accel-item">
            <div>
              <img src="/reimbursement.png" alt="MoneyLogo" className="accel-item-logo-img reimbursement" />
            </div>
            <p className="reimbursement-text">
              <strong>Providing immediate reimbursement</strong>
            </p>
          </div>
          <Divider orientation="vertical" flexItem />

          {/* Section 2 */}
          <div className="accel-item">
            <div>
              <img src="/protection.png" alt="MoneyLogo" className="accel-item-logo-img" />
            </div>
            <p>
              <strong>Offering Plan Protection, with a 360-day resolution window to resolve claims</strong>
            </p>
          </div>
          <Divider orientation="vertical" flexItem />

          {/* Section 3 */}
          <div className="accel-item">
            <div>
              <img src="/simplifying.png" alt="MoneyLogo" className="accel-item-logo-img" />
            </div>
            <p>
              <strong>Simplifying workflows and improving efficiency</strong>
            </p>
          </div>
        </div>
        <div className="closing-section">
        <AegleDivider color="#368caa" />
          <p className="end-tagline">
            Accel delivers greater value and consistency to all stakeholders:
          </p>
        </div>
      </section>

      {/* Plan Protection Details */}
      <section className="plan-protection-section">
        {isMobile ? <AccelGraphicMobile /> : <AccelGraphic />}
        <p className="benefits">
            By providing upfront funding, immediate reimbursement, and reducing administrative burdens, Accel delivers a better outcome for everyone within the independent self-funded ecosystem.
          </p>
          <Box sx={{ paddingTop: "40px"}}>
          <Button variant="contained" 
                  sx={{ backgroundColor: 'white !important', color: "#192e58 !important", boxShadow: 'none', '&:hover': { boxShadow: 'none' }}} 
                  onClick={() => {
                      window.scrollTo(0, 0);
                      navigate('/contact')
                  }}>
                      Learn How Accel Can Help
          </Button>
          </Box>
      </section>

    </div>
  );
};

export default AccelPage;