import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.css';

const Navbar: React.FC = () => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDrawer = (open: boolean) => () => {
    setIsDrawerOpen(open);
  };

  const getActiveLink = (path: string) => {
    switch (path) {
      case '/':
        return 'Home';
      case '/about':
        return 'Company';
      case '/accel':
        return 'Accel';
      case '/riskfinancing':
        return 'Risk Financing';
      case '/rbc':
        return 'RBC Support';
      case '/contact':
        return 'Contact';
      default:
        return '';
    }
  };

  const activeLink = getActiveLink(location.pathname);

  const navLinks = [
    { path: '/', label: 'Home' },
    { path: '/accel', label: 'Accel' },
    { path: '/about', label: 'Company' },
    { path: '/contact', label: 'Contact' }
  ];

  return (
    <nav className="Navbar">
      <div className="Navbar-logo">
        <Link to="/">
          <img src="/LogoWhite.png" alt="Logo" className="Navbar-logo-img" />
        </Link>
      </div>

      {/* Desktop Navigation */}
      {!isMobile && (
        <ul className="Navbar-links">
          {navLinks.map(({ path, label }) => (
            <li key={path}>
              <Link to={path} className={activeLink === label ? 'active' : ''}>
                {label}
              </Link>
            </li>
          ))}
        </ul>
      )}

      {/* Mobile Hamburger Icon (Only Shows on Small Screens) */}
      {isMobile && (
        <IconButton className="Navbar-menu-icon" onClick={toggleDrawer(true)}>
          <MenuIcon fontSize="large" sx={{ color: "white" }}/>
        </IconButton>
      )}

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="Navbar-drawer">
          <IconButton onClick={toggleDrawer(false)} className="Navbar-drawer-close">
            <CloseIcon />
          </IconButton>
          <List>
            {navLinks.map(({ path, label }) => (
              <ListItem
                key={path}
                component={Link as React.ElementType}
                to={path}
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary={label} className={activeLink === label ? 'active' : ''} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </nav>
  );
};

export default Navbar;