import React, { useEffect, useState } from 'react';
import './AccelTermsPage.css';
import { Box, Button, Divider, useMediaQuery } from '@mui/material';
import AegleDivider from '../components/AegleDivider';
import AccelGraphic from '../components/AccelGraphic';
import { useNavigate } from 'react-router-dom';
import AccelGraphicMobile from '../components/AccelGraphicMobile';
import ReactMarkdown from 'react-markdown';

const AccelTermsPage: React.FC = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
      fetch('/terms.md') // Adjust the path if it's elsewhere
        .then((res) => res.text())
        .then((text) => setContent(text));
    }, []);

    
  return (
    <div>
      {/* Header Section */}
      <section className="header-section">
        <ReactMarkdown>{content}</ReactMarkdown>
      </section>

    </div>
  );
};

export default AccelTermsPage;