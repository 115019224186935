import React from "react";
import "./AccelGraphic.css";

export const spokeData = [
    {
      key: "spoke1", // Keep the key for class name usage
      title: "Benefit Brokers & Consultants",
      details: [
        { icon: "/reimbursement.png", className: "reimbursement-img", text: ["Works 12 months per year, irrespective of the stop-loss carrier or TPA", "Revenue from Program Management "] },
        { icon: "/protection.png", className: "icon-img", text: ["Standardized experience across carriers"] },
      ],
    },
    {
      key: "spoke2",
      title: "Stop-Loss COEs\n& MGAs",
      details: [
        { icon: "/reimbursement.png", className: "reimbursement-img", text: ["Immediate reimbursement for client", "Works 12 months per year, irrespective of the stop-loss carrier or TPA", "Revenue from Program Management"] },
        { icon: "/protection.png", className: "icon-img", text: ["360-day resolution window for incomplete claims", "Standardized experience across carriers"] },
        { icon: "/simplifying.png", className: "icon-img", text: ["Simplified pricing model based on all-inclusive PEPM", "Aegle Aura streamlines claim filing", "Facilitates accurate claim auditing and reimbursement"] },
      ],
    },
    {
      key: "spoke3",
      title: "Health Plan Administrators",
      details: [
        { icon: "/reimbursement.png", className: "reimbursement-img", text: ["Reduces need for plan administrators to float large amounts of money", "Aligns claim funding requests with any network requirements – improving cash flow", "Revenue from Program Management"] },
        { icon: "/protection.png", className: "icon-img", text: ["Allows more time for data reconciliation and collection", "Works 12 months of the year, irrespective of the stop-loss carrier "] },
        { icon: "/simplifying.png", className: "icon-img", text: ["Facilitates claim auditing", "Facilitates a more functional marketplace for carriers where carriers bid on core underwriting capability rather than ancillary features", "Helps independent plan administrators adhere to national network Prompt Payment policies"] },
      ],
    },
    {
      key: "spoke4",
      title: "Carriers, MGUs, Captives, & Reinsurers",
      details: [
        { icon: "/reimbursement.png", className: "reimbursement-img", text: ["Provides an offering that better meets the demands of addressable market", "Provides carriers a lever that can help win competitive bidding situations", "Acts as an outsourced advanced funding mechanism which can lower premiums"] },
        { icon: "/protection.png", className: "icon-img", text: ["Reduce negative feedback from clients and intermediaries with a 360-day resolution window for incomplete claims"] },
        { icon: "/simplifying.png", className: "icon-img", text: ["Provides more time for carriers to audit claims, leading to greater reductions in underlying claims to be paid"] },
      ],
    },
    {
      key: "spoke5",
      title: "Employers",
      details: [
        { icon: "/reimbursement.png", className: "reimbursement-img", text: ["Immediate reimbursement after claim is filed", "Maintain consistent liquidity", "Get every dollar owed"] },
        { icon: "/protection.png", className: "icon-img", text: ["Up to 360 days to resolve incomplete claims", "Portability in maintaining reimbursement standards across carrier/TPA changes"] },
        { icon: "/simplifying.png", className: "icon-img", text: ["No process lags or procedural delays", "Only fund the stop-loss deductible instead of the full claim"] },
      ],
    },
  ] as const;  
  

const AccelGraphic: React.FC = () => {
    return (
      <div className="aegle-container">
        {/* Outer Circle */}
        <div className="outer-circle">
          {/* Center Circle */}
          <div className="aegle-accel-circle center">Aegle Accel</div>
  
          {/* Spokes with Hover Info */}
          {spokeData.map((spoke) => (
            <div key={spoke.key} className={`spoke ${spoke.key}`}>
              <div className="graphic-circle"><p>{spoke.title}</p></div>
  
              {/* Hover Box */}
              <div className="hover-box">
                <div className="hover-content">
                  <ul className="details-list">
                    {spoke.details.map((detail, i) => (
                      <li key={i} className="details-item">
                        <img src={detail.icon} alt="icon" className="icon-img" />
                        <div className="details-text">
                          {detail.text.map((t, j) => (
                            <li key={j}>{t}</li>
                          ))}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default AccelGraphic;
  
